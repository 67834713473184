import { createUniqueJobValidator, createValidJobNameValidator, websiteUrlValidator } from 'behaviour/jobs/validators';
import { bridgeTokenValidator } from 'behaviour/jobs/validators/testData/bridgeTokenValidator';
import { makeSchema } from 'components/form/builder';
import { SelectEditor } from 'components/form/editors';
import { urlRegEx } from 'components/job/configuration/general/schema';
import { extendSchema } from 'util/yup';
import * as Yup from 'yup';

const sanaVersions = ['SCC', 'SCC LTS'];

export const schemaGenerator = apollo => makeSchema({
  sections: [{
    inputs: [{
      name: 'jobName',
      label: 'Project name',
      defaultValue: '',
      size: 'large',
      validate: extendSchema(Yup.string()
        .required(),
        [
          createValidJobNameValidator,
          schema => createUniqueJobValidator(schema, apollo),
        ]),
    },
    {
      name: 'sanaVersion',
      label: 'Sana version',
      defaultValue: 'SCC',
      validate: Yup.string().required().oneOf(sanaVersions),
      input: SelectEditor,
      options: [
        { value: 'SCC' },
        { value: 'SCC LTS' }
      ],
    },
    {
      name: 'websiteUrl',
      alias: 'general.websiteUrl',
      label: 'Website url',
      defaultValue: '',
      helperText: 'The website url. E.g. https://demo.sana-commerce.com/',
      validate: extendSchema(Yup.string()
        .required()
        .matches(urlRegEx, 'Please provide a valid website url. E.g. https://demo.sana-commerce.com/')
        .matches('^((?!/admin).)*$', 'Looks like you have specified the url to Sana admin. '
          + 'Please use the url to the frontend instead.E.g. https://demo.sana-commerce.com/'),
        [
          schema => websiteUrlValidator(schema),
        ]),
    },
    {
      name: 'bridgeToken',
      alias: 'general.bridgeToken',
      label: 'Bridge token',
      defaultValue: '',
      validate: extendSchema(Yup.string().required(),
        [
          schema => bridgeTokenValidator(schema),
        ]),
      InputProps: {
        type: 'password',
      },
    }
    ],
  }],
});
