import { get } from 'lodash';

export const handleErrors = (name, error, setFieldError) => {
  const { graphQLErrors } = error;
  if (graphQLErrors) {
    graphQLErrors.forEach(err => {
      switch (get(err, 'extensions.code')) {
        case 'UNAUTHORIZED_ACCESS':
          setFieldError(
            name,
            'The hub cannot connect to the Sana webshop. Make sure the Bridge token is correct.'
          );
          break;
        case 'BRIDGE_NOT_INSTALLED':
          setFieldError(name, 'The hub cannot get test data because the bridge is not installed to the Sana webshop.');
          break;
        case 'ERP_CONNECTION_NOT_AVAILABLE':
          setFieldError(
            name,
            'The hub cannot get test data because the connection between Sana and the ERP is not available.'
          );
          break;
        case 'TIMEOUT':
          setFieldError(name, 'The hub did not receive a timely response from the Bridge.');
          break;
        case 'HTTP_REQUEST':
          setFieldError(name, 'The hub cannot connect to the Sana webshop. Make sure the website url is correct.');
          break;
        case 'IO':
          setFieldError(name, 'The request was aborted. Please, reload the page and try again.');
          break;
        default:
          setFieldError(
            name,
            'The hub cannot get the test data due to the error. Make sure your project has the latest SCC version.'
          );
          break;
      }
    });
  }
};
