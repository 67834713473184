import { gql, useApolloClient } from '@apollo/client';
import { get } from 'lodash';

const testBridgeTokenQuery = gql`
  query testBridgeTokenQuery(
    $websiteUrl: String!
    $bridgeToken: String!
  ) {
    testBridgeToken(websiteUrl: $websiteUrl, bridgeToken: $bridgeToken)
  }
`;

const apolloClient = useApolloClient();

export const bridgeTokenValidator = (schema, sanaVersion) =>
  schema.test(
    'validToken',
    'The bridge token is incorrect.',
    async function (value, context) {
      const {
        websiteUrl,
        bridgeToken,
        sanaVersion: contextSanaVersion
      } = context.parent;

      const version = sanaVersion || contextSanaVersion;

      if (!websiteUrl || !bridgeToken || !version?.includes('SCC')) return true;

      return apolloClient
        .query({
          query: testBridgeTokenQuery,
          variables: {
            websiteUrl,
            bridgeToken
          },
        })
        .then((result) => get(result, 'data.testBridgeToken'))
        .catch(() => true);
    }
  );
