import { gql, useApolloClient } from '@apollo/client';
import { useFormikContext } from 'formik';
import { concat, filter, get } from 'lodash';
import { useState } from 'react';

const getTestDataQuery = gql`
  query getTestDataQuery($jobName: String!, $field: String!, $relatedField: String, $take: Int!, $skip: Int!) {
    testData(jobName: $jobName, field: $field, relatedField: $relatedField, take: $take, skip: $skip) {
      items {
        id
        label
        extra
        relatedData {
          key
          value
        }
      }
    }
  }
`;

const useTestData = ({
  jobName, field, name, relatedField, parameters }) => {
  const {
    sanaVersion, websiteUrl, bridgeToken
  } = parameters;

  const apolloClient = useApolloClient();
  const { setFieldError, setFieldTouched } = useFormikContext();

  const take = 5;
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [relatedFieldValue, setRelatedFieldValue] = useState('');

  const loadMore = (skip = 0) => {
    // setFieldTouched to true to show an error message immediately, but not on blur
    setFieldTouched(name, true);
    setRelatedFieldValue(relatedField.value);
    setLoading(true);

    const relatedFieldValueChanged = relatedFieldValue !== relatedField.value;

    if (skip === 0 && options.length > 0 && !relatedFieldValueChanged) {
      setLoading(false);
      return;
    }

    if (skip > 0 && options.length === skip + 1) {
      const resultArray = options;
      resultArray.pop();
      resultArray.push({ id: 'loading', label: 'Loading…' });
      setOptions(resultArray);
    }

    if (!sanaVersion?.includes('SCC')) {
      setLoading(false);
      return;
    }

    if (!websiteUrl) {
      setFieldError(name, 'Re-check if website URL is filled in.');
      setLoading(false);
      return;
    }
    if (!bridgeToken) {
      setFieldError(name, 'Re-check if Bridge token is filled in.');
      setLoading(false);
      return;
    }

    if (relatedField.id && (!relatedField.value || relatedField.value === '-')) {
      setFieldError(name, `Related field "${relatedField.path}" is not filled.`);
      setLoading(false);
      return;
    }

    apolloClient.query({
      query: getTestDataQuery,
      variables: {
        jobName,
        field,
        relatedField: relatedField.value,
        take,
        skip,
      },
      fetchPolicy: 'no-cache',
    }).then((result) => {
      setLoading(false);
      const resultOptions = result.data.testData.items;

      if (resultOptions.length === 0 && skip === 0) {
        setOptions([{ id: 'noResults', label: 'No results' }]);
      } else {
        let resultArray = relatedFieldValueChanged ? [] : filter(options, r => r.id !== 'loading');
        resultArray = concat(resultArray, resultOptions);
        if (resultOptions.length === take) resultArray.push({ id: 'loadMore', label: `Load next ${take} options` });
        setOptions(resultArray);
      }
    }).catch((error) => {
      setLoading(false);
      const { graphQLErrors } = error;
      if (graphQLErrors) {
        graphQLErrors.forEach((err) => {
          switch (get(err, 'extensions.code')) {
            case 'UNAUTHORIZED_ACCESS':
              setFieldError(name, 'The hub cannot connect to the Sana webshop. Make sure the Bridge token is correct.');
              break;
            case 'BRIDGE_NOT_INSTALLED':
              setFieldError(name, 'The hub cannot get test data because the bridge is not installed to the Sana webshop.');
              break;
            case 'ERP_CONNECTION_NOT_AVAILABLE':
              setFieldError(name, 'The hub cannot get test data because the connection between Sana and the ERP is not available.');
              break;
            case 'TIMEOUT':
              setFieldError(name, 'The hub did not receive a timely response from the Bridge.');
              break;
            case 'HTTP_REQUEST':
              setFieldError(name, 'The hub cannot connect to the Sana webshop. Make sure the website url is correct.');
              break;
            case 'IO':
              setFieldError(name, 'The request was aborted. Please, reload the page and try again.');
              break;
            default:
              setFieldError(name, 'The hub cannot get the test data due to the error. Make sure your project has the latest SCC version.');
              break;
          }
        });
      }
    });
  };

  return {
    testData: options,
    loadMore,
    loading,
  };
};

export default useTestData;
