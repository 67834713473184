import { subscriptionTypes } from 'behaviour/jobs';
import { websiteUrlValidator } from 'behaviour/jobs/validators';
import { bridgeTokenValidator } from 'behaviour/jobs/validators/testData/bridgeTokenValidator';
import { makeSchema } from 'components/form/builder';
import { SelectEditor, SubscriptionEditor } from 'components/form/editors';
import { getValue } from 'util/getValue';
import { extendSchema } from 'util/yup';
import * as Yup from 'yup';
import { ShopType, TestCategory } from './constants';
import { CategoryListEditor, ReportPortalTagsEditor } from './editors';

export const urlRegEx = /((https?:\/\/|www\.|[^\s:=]+@www\.).*?[a-z_/0-9\-#=&])(?=(\.|,|;|\?|!)?("|'|«|»|\[|\s|\r|\n|$))/;

export const schemaGenerator = parameters => makeSchema({
  tabs: [{
    name: 'essential',
    title: 'Essential',
    sections: [{
      inputs: [
        {
          name: 'websiteUrl',
          alias: 'general.websiteUrl',
          label: 'Website url',
          defaultValue: parameters.websiteUrl || '',
          helperText: 'The website url. E.g. https://demo.sana-commerce.com/',
          validate: extendSchema(Yup.string()
            .required()
            .matches(urlRegEx, 'Please provide a valid website url. E.g. https://demo.sana-commerce.com/')
            .matches('^((?!/admin).)*$', 'Looks like you have specified the url to Sana admin. '
              + 'Please use the url to the frontend instead.E.g. https://demo.sana-commerce.com/'),
            [
              schema => websiteUrlValidator(schema, parameters.sanaVersion),
            ]),
        },
        {
          name: 'admin_email',
          alias: 'general.adminUser.email',
          label: 'Admin email',
          visible: !parameters.sanaVersion?.includes('SCC'),
          defaultValue: getValue(parameters.admin_email, ''),
          validate: Yup.string().when('sanaVersion', {
            is: (sanaVersion) => !sanaVersion?.includes('SCC'),
            then: Yup.string().required(),
          }),
        },
        {
          name: 'admin_password',
          alias: 'general.adminUser.password',
          label: 'Admin password',
          visible: !parameters.sanaVersion?.includes('SCC'),
          defaultValue: getValue(parameters.admin_password, ''),
          validate: Yup.string().when('sanaVersion', {
            is: (sanaVersion) => !sanaVersion?.includes('SCC'),
            then: Yup.string().required(),
          }),
          InputProps: {
            type: 'password',
          },
        },
        {
          name: 'bridgeToken',
          alias: 'general.bridgeToken',
          label: 'Bridge token',
          defaultValue: parameters.bridgeToken || '',
          validate: Yup.string().required(),
          visible: values => values.sanaVersion.includes('SCC'),
          validate: Yup.string().when('sanaVersion', {
            is: (sanaVersion) => sanaVersion?.includes('SCC'),
            then: extendSchema(Yup.string().required(),
              [
                schema => bridgeTokenValidator(schema),
              ]),
          }),
          InputProps: {
            type: 'password',
          },
        },
        {
          name: 'basicAuthentication_username',
          alias: 'general.basicAuthentication.username',
          label: 'NT Popup username',
          defaultValue: parameters.basicAuthentication_username || '',
          size: 'small',
          visible: !parameters.sanaVersion?.includes('SCC'),
        },
        {
          name: 'basicAuthentication_password',
          alias: 'general.basicAuthentication.password',
          label: 'NT Popup password',
          defaultValue: parameters.basicAuthentication_password || '',
          size: 'small',
          visible: !parameters.sanaVersion?.includes('SCC'),
          InputProps: {
            type: 'password',
          },
        },
        {
          name: 'sanaVersion',
          label: 'Sana version',
          defaultValue: parameters.sanaVersion || 'SCC',
          input: SelectEditor,
          visible: parameters.sanaVersion?.includes('SCC'),
          options: [{ value: 'SCC' }, { value: 'SCC LTS' }]
        },
        {
          name: 'subscriptionType',
          alias: 'general.subscriptionType',
          label: 'Subscription type',
          defaultValue: parameters.subscriptionType || subscriptionTypes.Advanced,
          input: SubscriptionEditor,
          visible: parameters.sanaVersion?.includes('SCC'),
        },
        {
          name: 'shopType',
          alias: 'general.shopType',
          label: 'Shop type',
          defaultValue: parameters.shopType || ShopType.Public,
          visible: values => values.subscriptionType !== subscriptionTypes.Essential,
          input: SelectEditor,
          options: [
            { title: 'Public', value: ShopType.Public },
            { title: 'Private', value: ShopType.Private },
            { title: 'Closed', value: ShopType.Closed },
          ],
        },
        {
          name: 'categories',
          alias: 'tests.includes',
          defaultValue: parameters.categories || Object.values(TestCategory),
          input: CategoryListEditor,
          options: [
            { title: 'Scheduled tasks check', value: TestCategory.ScheduledTasksCheck },
            { title: 'Anonymous customer', value: TestCategory.AnonymousCustomer },
            { title: 'B2C customer', value: TestCategory.B2CCustomer },
            { title: 'B2B customer', value: TestCategory.B2BCustomer },
            { title: 'Contact', value: TestCategory.Contact },
            { title: 'Sales agent', value: TestCategory.SalesAgent },
            { title: 'Prospect', value: TestCategory.Prospect },
            { title: 'Guest checkout', value: TestCategory.GuestCheckout },
            { title: 'Shop account roles', value: TestCategory.ShopAccountRoles },
            { title: 'Simple products', value: TestCategory.SimpleProducts },
            { title: 'Variant products', value: TestCategory.VariantProducts },
            { title: 'Order', value: TestCategory.Order },
            { title: 'Quote', value: TestCategory.Quote },
            { title: 'Quote to order conversion', value: TestCategory.QuoteToOrderConversion },
            { title: 'Product returns', value: TestCategory.ProductReturns },
            { title: 'Sales agreements', value: TestCategory.SalesAgreements },
            { title: 'Credit limit', value: TestCategory.CreditLimit },
            { title: 'Promotions', value: TestCategory.Promotions },
            { title: 'Order attachments', value: TestCategory.OrderAttachments },
            { title: 'Product attachments', value: TestCategory.ProductAttachments },
            { title: 'Sales reports', value: TestCategory.SalesReports },
            { title: 'Online invoice payments', value: TestCategory.OnlineInvoicePayments },
            { title: 'Category import', value: TestCategory.CategoryImport },
            { title: 'Payment method', value: TestCategory.PaymentMethod, visible: parameters.sanaVersion !== '930' },
            { title: 'Shipping method', value: TestCategory.ShippingMethod, visible: parameters.sanaVersion !== '930' },
            { title: 'B2B registration', value: TestCategory.B2BRegistration, visible: parameters.sanaVersion?.includes('SCC') },
            { title: 'Locations', value: TestCategory.Locations, visible: parameters.sanaVersion?.includes('SCC') }
          ],
        },
        {
          name: 'reportPortal_Tags',
          alias: 'reportPortal.launch.tags',
          label: 'Report Portal tags',
          placeholder: 'Press \'Enter\' to add new tag',
          defaultValue: parameters.reportPortal_Tags || [],
          input: ReportPortalTagsEditor,
        },
        {
          name: 'updateSettings',
          alias: 'general.updateSettings',
          label: 'Modify the webstore settings',
          defaultValue: parameters.updateSettings || false,
          helperText: 'Allows the test runner to change the webstore settings. This will not affect other users of the website as the updated configuration is only available to the test runner itself.',
          input: SelectEditor,
          options: [
            { title: 'Allow', value: 'true' },
            { title: 'Deny', value: 'false' },
          ],
        },
        {
          name: 'failTestIfSettingsMismatch',
          alias: 'general.failTestIfSettingsMismatch',
          label: 'Verify webstore settings',
          defaultValue: parameters.failTestIfSettingsMismatch || true,
          helperText: 'Fails the test in case the webstore settings does not meet expectations for the included categories.',
          input: SelectEditor,
          disabled: values => values.updateSettings === 'true',
          disabledReason: 'Not applicable as the webstore settings modifications is allowed.',
          options: [
            { title: 'Yes', value: 'true' },
            { title: 'No', value: 'false' },
          ],
        },
      ],
    }],
  }, {
    name: 'advanced',
    title: 'Advanced',
    sections: [{
      inputs: [
        {
          name: 'takeScreenshotOnFailure',
          alias: 'general.takeScreenshotOnFailure',
          label: 'Take screenshot on failure',
          defaultValue: parameters.takeScreenshotOnFailure || true,
          helperText: 'Makes screenshots of failed tests available on the test report.',
          input: SelectEditor,
          options: [
            { title: 'Yes', value: 'true' },
            { title: 'No', value: 'false' },
          ],
        },
        {
          name: 'takeFullPageScreenshot',
          alias: 'general.takeFullPageScreenshot',
          label: 'Take full-page screenshot',
          defaultValue: parameters.takeFullPageScreenshot || true,
          helperText: 'Allows the test runner to take full-page screenshots.',
          input: SelectEditor,
          disabled: values => values.takeScreenshotOnFailure === 'false',
          disabledReason: 'Not applicable since screenshotting is turned off',
          options: [
            { title: 'Yes', value: 'true' },
            { title: 'No', value: 'false' },
          ],
        },
        {
          name: 'attachLogFiles',
          alias: 'general.attachLogFiles',
          label: '​Attach log files',
          defaultValue: parameters.attachLogFiles || true,
          helperText: 'Makes log files available on the test report.',
          input: SelectEditor,
          options: [
            { title: 'Yes', value: 'true' },
            { title: 'No', value: 'false' },
          ],
        },
        {
          name: 'waitUntilDocumentReadyTimeout',
          alias: 'general.waitUntilDocumentReadyTimeout',
          label: 'Wait until document ready timeout',
          defaultValue: parameters.waitUntilDocumentReadyTimeout || 60,
          helperText: 'Timeout in seconds',
          type: 'number',
          validate: Yup.number().required().typeError().min(0),
          size: 'small',
        },
        {
          name: 'commandTimeout',
          alias: 'selenium.driver.commandTimeout',
          label: 'Selenium command timeout',
          defaultValue: parameters.commandTimeout || 60,
          helperText: 'Indicates how long a Selenium web driver should wait response from a Selenium server. For performance reason it cannot be longer than 300 seconds.',
          type: 'number',
          validate: Yup.number().required().typeError().min(0)
            .max(300),
          size: 'small',
        },
      ],
    }],
  }],
});
